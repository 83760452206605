import React from "react";
import MainLayout from "../layouts/Main";
import Button from "../Components/Button/Button";
import "../Components/Page/Abx-Competitor/styles.scss";
import LogoWallTicker from "../Components/General/LogoWallTicker/LogoWallTicker";
import DownloadForm from "../Components/Page/Resources/DownloadForm/DownloadForm";
import CompetitorStatCards from "../Components/Page/Abx-Competitor/CompetitorStatCards/index.jsx";
import Divider from '../Components/General/Divider/Divider.jsx'
import { Helmet } from 'react-helmet';

const logos = [
  { logo: { sourceUrl: "/images/abx-competitor/ClarinsLogo.svg" } },
  { logo: { sourceUrl: "/images/abx-competitor/Diane_Von_Furstenberg.png" } },
  {
    logo: { sourceUrl: "/images/abx-competitor/Hairstory_logo_black_Logo.png" },
  },
  {
    logo: {
      sourceUrl: "/images/abx-competitor/Saks-O5_Main-Lockup_RGB_Black-3.png",
    },
  },
  { logo: { sourceUrl: "/images/abx-competitor/Wolverine_Worldwide.png" } },
  { logo: { sourceUrl: "/images/abx-competitor/ColeHaan_Wordmark_2014.svg" } },
  { logo: { sourceUrl: "/images/abx-competitor/Soludos_Logo_240x@2x 2.svg" } },
];

function AbxCompetitor() {
  return (
    <MainLayout className="page-home">
      <Helmet>
      <title>Retention vs. Wunderkind: Compare Email Marketing Platforms</title>
        <meta
          name="description"
          content="Discover the key differences between Retention and Wunderkind, two leading email marketing platforms. Learn about features, pricing, and performance to make the best choice for your business."
        />
        <meta
          name="keywords"
          content="Retention vs Wunderkind, email marketing platforms, email marketing comparison, email marketing features, marketing tools, Retention platform, Wunderkind platform"
        />
        <meta property="og:title" content="Retention vs. Wunderkind: Compare Email Marketing Platforms" />
        <meta
          property="og:description"
          content="Discover the key differences between Retention and Wunderkind, two leading email marketing platforms. Learn about features, pricing, and performance to make the best choice for your business."
        />
        <meta property="og:url" content="https://www.wunderkind.co/retention-vs-wunderkind/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.wunderkind.co/retention-vs-wunderkind/" />
      </Helmet>
      <section className="abx-competitor-wrapper">
        <div className="abx-competitor-header-container">
          <div className="abx-competitor-header-half">
            <h6 className="abx-competitor-top-header">
              Wunderkind vs Retention.com
            </h6>
            <h1>
              Boost email revenue by up to 10x* without sacrificing
              deliverability
            </h1>
            <h5>
              Wunderkind identifies 1B profiles annually vs Retention.com’s 250M
              total
            </h5>
            <ul>
              <li>Identify 1 in 3 visitors to your website</li>
              <li>Drive 6x more revenue than Retention.com, on average</li>
            </ul>
            <a href="#download-form-section">
              <Button className="btn btn-fill">Get Started</Button>
            </a>
            <h6>
              *Lift in triggered email performance for a Health & Wellness
              brand, previously with Retention
            </h6>
          </div>
          <div className="abx-competitor-image-container">
            <img src="/images/abx-competitor/header-image-abx.png" alt="" />
          </div>
        </div>
      </section>
      <Divider
       direction="bottom-right"
       bgColor={`#f5ebe1`}
       maskColor={`#191919`}
       />
      <div className="abx-competitor-logo-ticket-wrapper">
        <h2>
          $5 billion in revenue generated for retail and eCommerce brands in
          2024
        </h2>
        <LogoWallTicker
          className="abx-competitor-logo-ticket-wrapper"
          logos={logos}
        />
      </div>
      <Divider
       direction="top-right"
       bgColor={`#f5ebe1`}
       maskColor={`#303d78`}
       />
      <section className="abx-identification-wrapper">
        <div className="abx-identification-container">
          <div className="abx-identification-full-container">
            <div className="abx-identification-half-container">
              <h2>
                Wunderkind has the highest identification rates on the market
              </h2>
              <p>
                Retention's smaller identity network relies on cookies and
                third-party data, limiting your ability to engage potential
                customers.
              </p>
              <p>
                Wunderkind recognizes up to 10X more visitors that show real
                intent on your site, so you can send more targeted emails and
                drive substantial revenue growth.
              </p>
            </div>
            <div className="abx-identification-half-image-container">
              <img
                src="/images/abx-competitor/700x380_Unknown-to-known-Animation-3seconds.gif"
                alt="gif"
              />
            </div>
          </div>
          <div className="abx-identification-image-flex-container">
            <div className="abx-identification-image-text-container">
              <img src="/images/abx-competitor/Group-425.svg" alt="" />
              <h4 className="abx-identification-image-text-header">
                Identify 1 in 3 visitors
              </h4>
              <p>
                Through our vast Identity Network of 1 billion consumer
                profiles.
              </p>
            </div>
            <div className="abx-identification-image-text-container">
              <img src="/images/abx-competitor/Group-222.svg" alt="" />
              <h4 className="abx-identification-image-text-header">
                2 Trillion events tracked per year
              </h4>
              <p>Send more effective emails with deeper customer data.</p>
            </div>
            <div className="abx-identification-image-text-container">
              <img src="/images/abx-competitor/Group-483.svg" alt="" />
              <h4 className="abx-identification-image-text-header">
                Maintain email deliverability
              </h4>
              <p>
                Keep your sender score high. Only email visitors identified by
                first-party data.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Divider
       direction="top-left"
       bgColor={`#303d78`}
       maskColor={`#f5ebe1`}
       />
      <section className="abx-competitor-stats-and-card-section">
        <div className="abx-competitor-stats-and-card-desc-container">
          <div className="abx-competitor-stats-container">
            <h3>3.1%</h3>
            <p>
              of total digital revenue from one-to-one email, measured last
              click
            </p>
            <div className="abx-competitor-number-border" />
            <h3>240%</h3>
            <p>lift in triggered abandonment email revenue vs. Retention.com</p>
            <div className="abx-competitor-number-border" />
            <h3>-70%</h3>
            <p>
              Decrease in triggered email revenue when powered by Retention.com
            </p>
          </div>
          <div className="abx-competitor-stat-section-desc-container">
            <div className="abx-competitor-stat-section-border" />
            <div>
              A luxury home & furniture brand migrated their triggered emails
              from Wunderkind to Retention.com to reduce costs.
            </div>
            <div>
              After experiencing a considerable drop in abandonment email
              revenue, the brand returned to Wunderkind to reverse the decline.
            </div>
            <div>
              Our identification advantage continues to scale email opt-ins and
              performance.
            </div>
            <div className="abx-competitor-stat-section-border" />
          </div>
        </div>
        <h2>6 reasons retailers choose Wunderkind</h2>
        <CompetitorStatCards />
      </section>
      <Divider
       direction="top-left"
       bgColor={`#f5ebe1`}
       maskColor={`#191919`}
       />
      <section className="abx-competitor-wknd-guarantee-section">
        <div className="abx-competitor-wknd-guarantee-image-container">
          <h2>The Wunderkind</h2>
          <img src="/images/abx-competitor/red-blue-block.svg" alt="pattern" />
        </div>
        <div className="abx-competitor-wknd-guarantee-image-container-2">
          <img
            src="/images/abx-competitor/black-yellow-block.svg"
            alt="pattern"
          />
          <h2>guarantee</h2>
        </div>
        <p>
          Guaranteed boost in performance based on additional last-click revenue
          from defined triggered emails sent to engaged, mailable users in your
          CRM.
        </p>
        <p>
          <em>Your contract includes</em> a specific dollar amount we’re ready to
          guarantee through our identification and triggered email campaigns.
        </p>
      </section>
      <Divider
       direction="top-right"
       bgColor={`#191919`}
       maskColor={`#303d78`}
       />
      <section className="abx-competitor-retention-vs-wknd-section">
        <h6>Retention vs Wunderkind</h6>
        <h2>Wunderkind numbers speak for themselves</h2>
        <img src="/images/abx-competitor/wknd-vs-retention-table.svg" alt="" />
      </section>
      <Divider
       direction="bottom-right"
       bgColor={`#191919`}
       maskColor={`#303d78`}
       />
      <section className="abx-competitor-retention-vs-wknd-bar-section">
        <h2>Wunderkind outperforms Retention.com email revenue by up to 10x</h2>
        <img
          src="/images/abx-competitor/wknd-vs-retention-bar-graph.svg"
          alt=""
        />
        <p>
          Compares US performance of abandonment triggered emails over one year
          to performance of comparable Wunderkind triggered emails (eg, cart and
          browse) after switching.
        </p>
      </section>
      <Divider
       direction="top-right"
       bgColor={`#191919`}
       maskColor={`#f5ebe1`}
       />
      <section className="abx-competitor-white-glove-migration">
        <div className="abx-competitor-white-glove-overall-container">
          <div className="abx-competitor-white-glove-header-badge-container">
            <h2>White glove migration from Retention</h2>
            <p>
              We’ll only ask you to place a pixel on your site. The Wunderkind
              team takes care of strategy, copywriting, development, quality
              assurance testing, and campaign activation.
            </p>
          </div>
          <div
            id="download-form-section"
            className="abx-competitor-white-glove-form-container"
          >
            <DownloadForm formId={`2293`} />
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default AbxCompetitor;
